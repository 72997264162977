import React from "react"
import styles from "../../assets/scss/member/policy.module.scss"

export default () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h2 className={styles["header"]}>活動ポリシー</h2>
        <div className={styles["policy"]}>
          <h4 className={styles["policy__header"]}>
            箕澤屋の活動を維持するために考えていること
          </h4>

          <div className={styles["policy__items"]}>
            <div className={styles["policy__item"]}>
              <h5 className={styles["policy__item__header"]}>活動の目的</h5>
              箕澤屋の建物を残すと共に、参加メンバーが居心地のよい場をつくること
            </div>
            <div className={styles["policy__item"]}>
              <h5 className={styles["policy__item__header"]}>
                サポーターについて
              </h5>
              <div className={styles["policy__item__content"]}>
                <h6 className={styles["policy__item__header_s"]}>
                  箕澤屋サポーターとは
                </h6>
                箕澤屋の活動に賛同し、サポーターとして参加希望してくださる、下記の基準を満たす方を箕澤屋サポーターとさせていただきます。
                <br />
                ・箕澤屋の活動を年間で1日以上手伝っていただいた方（仮）
                <br />
                ※今後、参加の方法は追加予定
              </div>
              <div className={styles["policy__item__content"]}>
                <h6 className={styles["policy__item__header_s"]}>
                  参加のルール
                </h6>
                ・箕澤屋を大切に扱う
                <br />
                ・他のメンバーと良好な関係を保つ <br />
                ・自分が楽しむ
              </div>
              <div className={styles["policy__item__content"]}>
                <h6 className={styles["policy__item__header_s"]}>
                  サポーターのメリット
                </h6>
                ・サポーター同士の交流
                <br />
                ・箕澤屋の施設を遊び場として使える
                <br />
                ・自分がやりたいことを実現する場にできるチャンスあり
                <br />
                <small>
                  （無制限にOKというわけにはいかないので、まずはアイデアとして意見もらえれば管理メンバーで検討します）
                </small>
              </div>
              <div className={styles["policy__item__content"]}>
                <h6 className={styles["policy__item__header_s"]}>
                  箕澤屋クラウドについて
                </h6>
                サポーターが交流や情報共有できるオンラインの場
                <br />
                ※現在はテスト運用としてコアメンバーのみ利用
              </div>
            </div>
            <div className={styles["policy__item"]}>
              <h5 className={styles["policy__item__header"]}>
                箕澤屋の施設利用について
              </h5>
              <p>
                持続可能な形で運用を目指すために、最低限のルールを設定したいと思います。
              </p>
              <p>
                ・利用できるのは箕澤屋サポーターのみ（1人のサポーターに対して3人まで同伴可能）
                <br />
                ・サポーターは、箕澤屋サポートとして寄付金を支払っていただくことで、個人利用にて箕澤屋大広間、客間を利用することができる（今年はお試しで、1日3,000円）
                <br />
                ・タオル等の用意はないので、必要な場合はご自身で手配していただく（宿泊用ではないので、布団も用意はありません）
                <br />
                ・箕澤屋利用後は、利用した場所を掃除して返却する（掃除道具は用意してあります）
              </p>
            </div>
            <p>
              いろいろと面倒なこともありますが、持続可能な形で運営していくために、ご協力をよろしくお願いいたします。
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
